import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Main.css";
import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  BarChartOutlined
} from "@ant-design/icons";
import { Card, Space, Statistic, Table, Typography, Menu, Spin } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Cookies from "js-cookie";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function Main() {
  const [orders, setOrders] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [revenue, setRevenue] = useState(0);
 const [sales, setSales] = useState(0)
  const [JsSales, setJsSales] = useState(0)
  const [Commission, setCommission] = useState(0)
  const [topCustomersData, setTopCustomersData] = useState([]);
  const [customersData, setCustomersData] = useState([]);
  const [revenueData, setRevenueData] = useState({
    labels: [],
    datasets: [],
  });
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const Ledcode = sessionStorage.getItem('Ledcode');
  // console.log("Ledcode:", Ledcode);

  const AgentCode = sessionStorage.getItem('AgentCode');
  // const AgentCode = rawAgentCode === "0" ? "-1" : rawAgentCode;
  // console.log("AgentCode:", AgentCode);

  const customerType = sessionStorage.getItem('CustomerType');
  console.log("hey", customerType);

 if (Ledcode === 0 || Ledcode === null || Ledcode === undefined) {
    // Return back from the page or perform any desired action
    window.history.back();
  }

  useEffect(() => {
    // Push a new state to the history stack when the component mounts
    window.history.pushState(null, null, window.location.pathname);

    // Add a listener to the 'popstate' event
    window.addEventListener('popstate', handlePopstate);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const handlePopstate = () => {
    // Push another state to the history stack, effectively preventing the user from going back
    window.history.pushState(null, null, window.location.pathname);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await fetch(`${apiBaseUrl}/dashboard/getTotal/JAPAN_ACCESORIES?days=30&ledcode=${encodeURIComponent(Ledcode)}&agentCode=${encodeURIComponent(AgentCode)}`);
        const data1 = await response1.json();
        const [{ Orders, Customers, Profit, Sales }] = data1;

        const response2 = await fetch(`${apiBaseUrl}/dashboard/getTotalJss/JAPAN_ACCESORIES?days=30&ledcode=${encodeURIComponent(Ledcode)}&agentCode=${encodeURIComponent(AgentCode)}`);
        const data2 = await response2.json();
        const [{ Sales: JsSales, Commission }] = data2;

        setOrders(Orders);
        setCustomers(Customers);
        setRevenue(Profit);
        setSales(Sales);
        setJsSales(Number(JsSales).toFixed(2));
        setCommission(Number(Commission).toFixed(2));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [AgentCode, Ledcode]);

  useEffect(() => {
    const fetchTopCustomers = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/dashboard/TopCustomers/JAPAN_ACCESORIES?ledcode=${encodeURIComponent(Ledcode)}&agentCode=${encodeURIComponent(AgentCode)}`);
        const data = await response.json();
        // console.log("API response data:", data);
        setTopCustomersData(data); // Update to set the array of objects
      } catch (error) {
        console.error("Error fetching top customers data:", error);
      }
    };

    fetchTopCustomers();

  }, [AgentCode, Ledcode]);


  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/dashboard/CustomerList/JAPAN_ACCESORIES?days=30&ledcode=${encodeURIComponent(Ledcode)}&agentCode=${encodeURIComponent(AgentCode)}`);
        const data = await response.json();
        // console.log("customer name???:", data);
        setCustomersData(data); // Update to set the array of objects
      } catch (error) {
        console.error("Error fetching top customers data:", error);
      }
    };

    fetchCustomers();

  }, [AgentCode, Ledcode]);

  useEffect(() => {
    const fetchOrderRevenue = async () => {
      try {
        let apiUrl = `${apiBaseUrl}/dashboard/OrderRevenue/JAPAN_ACCESORIES?ledcode=${encodeURIComponent(Ledcode)}&agentCode=${encodeURIComponent(AgentCode)}`;
        let labelsKey = 'DDate';
        let profitsKey = 'Profit';

       if (customerType === 'JS_MANAGER' || customerType === 'JS_EMPLOYEE' || customerType === 'TK_MANAGER' || customerType === 'TK_EMPLOYEE') {
          apiUrl = `${apiBaseUrl}/dashboard/OrderRevenueJs/JAPAN_ACCESORIES?ledcode=${encodeURIComponent(Ledcode)}&agentCode=${encodeURIComponent(AgentCode)}`;
          labelsKey = 'Date';
          profitsKey = 'Commision';
        }

        const response = await fetch(apiUrl);
        const data = await response.json();
        const labels = data.map((item) => item[labelsKey]);
        const profits = data.map((item) => item[profitsKey]);
        setRevenueData({
          labels: labels,
          datasets: [
            {
              label: "Order Revenue",
              data: profits,
              backgroundColor: "rgba(0,123,255,0.5)",
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching order revenue data:", error);
      }
    };

    fetchOrderRevenue();

  }, [AgentCode, Ledcode, customerType]);


  const LedName = sessionStorage.getItem("LedName");
  // console.log("ledname:", LedName);

  const handleLogOut = () => {
    sessionStorage.removeItem("Ledcode"); 
    sessionStorage.removeItem("LedName");
    localStorage.removeItem('selectedProducts');
    sessionStorage.clear();
    Cookies.remove("isLoggedIn");
    Cookies.remove("username");
    Cookies.remove("customerType");
    Cookies.remove("ledCode");
    Cookies.remove("ledName");
  };

  return (
    <div className="page-wrapper">
      <div className="dashboard-container">
        <div className="sidebar">
          <Menu mode="vertical" className="menu1">
            <div className="home-container" id="key">
              <img
                src="http://148.72.210.101:866/logo/logo.jpg"
                alt="Example"
                className="example-image"
                id="menu-logo"
                onError={(e) => {
                  e.target.src = "https://i.postimg.cc/FFc0w6KZ/ALIK-LOGO.png";
                }}
              />
            </div>
            <div className="LedName" id="led1">{LedName !== null && <p>Hi, {LedName}</p>}</div>
            <div className="menu-items-container">
              <Menu.Item key="home" className="menu-item" id="last-menu">
                <Link to="/Home">Order</Link>
              </Menu.Item>
              {customerType !== 'JS_EMPLOYEE' && customerType !== 'JS_MANAGER' && customerType !== 'TK_EMPLOYEE' && customerType !== 'TK_MANAGER' && (
                <Menu.Item key="orderList" className="menu-item" id="last-menu">
                  <Link to="/Order">Order List</Link>
                </Menu.Item>
              )}

              <Menu.Item key="aboutUs" className="menu-item" id="last-menu">
                <Link to="/About">About Us</Link>
              </Menu.Item>
              <Menu.Item key="logout" className="menu-item" id="last-menu">
                <Link to="/" onClick={handleLogOut}>Log Out</Link>
              </Menu.Item>
            </div>
          </Menu>
        </div>

        <div className="content1">
          <Space size={20} direction="vertical">
            <Typography.Title level={3}>Home</Typography.Title>
            <Space direction="horizontal">
              <div className="top-content" >
                <DashboardCard
                  icon={
                    <ShoppingCartOutlined
                      style={{
                        color: "green",
                        backgroundColor: "rgba(0,255,0,0.25)",
                        borderRadius: 20,
                        fontSize: 24,
                        padding: 8,
                      }}
                    />
                  }
                  title={"Orders"}
                  value={orders}
                />
              </div>
              {customerType !== 'JS_EMPLOYEE' && customerType !== 'TK_EMPLOYEE' && (
              <div className="top-content">
                <DashboardCard
                  icon={
                    <UserOutlined
                      style={{
                        color: "purple",
                        backgroundColor: "rgba(0,255,255,0.25)",
                        borderRadius: 20,
                        fontSize: 24,
                        padding: 8,
                      }}
                    />
                  }
                  title={"Customers"}
                  value={customers}
                />
              </div>
              )}

              {customerType !== 'JS_MANAGER' && customerType !== 'JS_EMPLOYEE'  && customerType !== 'TK_MANAGER'  && customerType !== 'TK_EMPLOYEE' && (
                <>
                  <div className="top-content" >
                    <DashboardCard
                      icon={
                        <BarChartOutlined
                          style={{
                            color: "blue",
                            backgroundColor: "rgba(0,0,255,0.25)",
                            borderRadius: 20,
                            fontSize: 24,
                            padding: 8,
                          }}
                        />
                      }
                      title={"Sales"}
                      value={sales}
                    />
                  </div>
                  {customerType !== 'DEALER' && (
                    <div className="top-content">
                      <DashboardCard
                        icon={
                          <DollarCircleOutlined
                            style={{
                              color: "red",
                              backgroundColor: "rgba(255,0,0,0.25)",
                              borderRadius: 20, 
                              fontSize: 24,
                              padding: 8,
                            }}
                          />
                        }
                        title={"Profit"}
                        value={revenue}
                      />
                    </div>
                  )}
                </>
              )}

              {(customerType === 'JS_MANAGER' || customerType === 'JS_EMPLOYEE' || customerType === 'TK_MANAGER' || customerType === 'TK_EMPLOYEE') && (
                <>
                  <div className="top-content" >
                    <DashboardCard
                      icon={
                        <BarChartOutlined
                          style={{
                            color: "blue",
                            backgroundColor: "rgba(0,0,255,0.25)",
                            borderRadius: 20,
                            fontSize: 24,
                            padding: 8,
                          }}
                        />
                      }
                      title={"Sales"}
                      value={JsSales}
                    />
                  </div>
                  {/* <div className="top-content" >
                    <DashboardCard
                      icon={
                        <DollarCircleOutlined
                          style={{
                            color: "red",
                            backgroundColor: "rgba(255,0,0,0.25)",
                            borderRadius: 20,
                            fontSize: 24,
                            padding: 8,
                          }}
                        />
                      }
                      title={"Target"}
                      value={Commission}
                    />
                  </div> */}
                </>
              )}


            </Space>

            <Space>
              {customerType !== 'JS_EMPLOYEE' && customerType !== 'JS_MANAGER' && customerType !== 'TK_MANAGER' && customerType !== 'TK_EMPLOYEE' && (

                <div className="top-customer" >
                  <DashboardList

                    title="Top Customers"
                    data={topCustomersData}
                    columns={[
                      {
                        title: "Name",
                        dataIndex: "Name",
                        key: "name",
                      },
                    ]}
                  />
                </div>
              )}
            </Space>

            <Space className="revenueandrecent" >

              <div className="revenue">
                
                <DashboardChart revenueData={revenueData} />
                <div className="revenueSub">
                {customerType !== 'DEALER' && customerType !== 'AGENT' && customerType !== 'TK_MANAGER' && customerType !== 'TK_EMPLOYEE'  && (
                <JsProfit />

              )}
               {customerType !== 'DEALER' && customerType !== 'AGENT' && customerType !== 'JS_MANAGER' && customerType !== 'JS_EMPLOYEE' && (
                <TkProfit />

              )}
                </div>
                {/* <div className="revenueSub">
               
                </div> */}
              </div>
              
              {/* <div className="recent-order">
              </div> */}
             
            </Space>

            <Space>
              <div className="recent-order">
                <RecentOrders />
              </div>
                 {customerType !== 'DEALER' && customerType !== 'AGENT' && customerType !== 'JS_EMPLOYEE'  && customerType !== 'TK_EMPLOYEE'  && customerType !== 'TK_MANAGER' && (
              <div className="recent-order6">
              <Summary/>
              </div>
               )}
               {customerType !== 'DEALER' && customerType !== 'AGENT' && customerType !== 'JS_EMPLOYEE'  && customerType !== 'TK_EMPLOYEE'  && customerType !== 'JS_MANAGER' && (
              <div className="recent-order6">
              <Summary2/>
              </div>
               )}
              {/* {customerType !== 'DEALER' && customerType !== 'AGENT' && (
              <div className="recent-order">
                <JsProfit />    

              </div>
              )} */}
            </Space>
            {customerType !== 'JS_EMPLOYEE' && customerType !== 'JS_MANAGER'  && customerType !== 'TK_MANAGER'  && customerType !== 'TK_EMPLOYEE' && (

              <div className="customer">
                <DashboardList

                  title="Customers"
                  data={customersData}
                  columns={[
                    {
                      title: "Name",
                      dataIndex: "Customer",
                      key: "Customer",
                    },
                  ]}
                />
              </div>
            )}
          </Space>

        </div>
      </div>
    </div>
  );
}

function DashboardCard({ title, value, icon }) {
  return (

    <Card>
      <Space direction="horizontal">
        {icon}
        <Statistic title={title} value={value} />
      </Space>
      {/* <div> 
          <Typography.Paragraph direction="vertical">
            (30 days)
          </Typography.Paragraph>
        </div> */}
    </Card>
  );
}

function RecentOrders() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const Ledcode = sessionStorage.getItem('Ledcode');
  console.log("Ledcode:", Ledcode);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


  const AgentCode = sessionStorage.getItem('AgentCode');
  // const AgentCode = rawAgentCode === "0" ? "-1" : rawAgentCode;
  // console.log("AgentCode:", AgentCode);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/dashboard/RecentOrders/JAPAN_ACCESORIES?ledcode=${encodeURIComponent(Ledcode)}&agentCode=${encodeURIComponent(AgentCode)}`);
        const data = await response.json();
        const formattedData = data.map(item => {
          // console.log("Price:", item.Price);
          return {
            title: item.Title,
            quantity: item.Qty,
            price: item.Price
          };
        });
        setDataSource(formattedData);
      } catch (error) {
        console.error("Error fetching recent orders data:", error);
      }
    };

    fetchOrders();

  }, [AgentCode, Ledcode]);

  return (

    <Card className="jsGoal jsGoalBorder">
      <Typography.Text className="jsGoal">Recent Orders</Typography.Text>
      <Table 
        columns={[
          {
            title: "Title",
            dataIndex: "title",
          },
          {
            title: "Quantity",
            dataIndex: "quantity",
          },
          {
            title: "Price",
            dataIndex: "price",
          }
        ]}
        loading={loading}
        dataSource={dataSource}
        pagination={false}
      />
    </Card>
    
  );
}

function JsProfit() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalSalesAmount, setTotalSalesAmount] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);

  const Ledcode = sessionStorage.getItem('Ledcode');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const AgentCode = sessionStorage.getItem('AgentCode');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${apiBaseUrl}/dashboard/categorywisecommission/JAPAN_ACCESORIES?ledcode=${encodeURIComponent(Ledcode)}&agentCode=${encodeURIComponent(AgentCode)}`);
        const data = await response.json();
  
        // Calculate total SalesAmount and Commission
        let totalSales = 0;
        let totalComm = 0;
  
        const formattedData = data.map(item => {
          // Convert SalesAmount, Target, and Commision to 2 decimal places
          const formattedSalesAmount = item.SalesAmount.toFixed(2);
          const formattedTarget = item.Target.toFixed(2);
          const formattedCommision = item.Commision.toFixed(2);
  
          totalSales += item.SalesAmount;
          totalComm += item.Commision;
  
          return {
            Category: item.Category,
            SalesAmount: formattedSalesAmount,
            Target: formattedTarget,
            Commision: formattedCommision
          };
        });
  
        // Set total values
        const formattedTotalSales = totalSales.toFixed(2);
        const formattedTotalComm = totalComm.toFixed(2);
        
        setTotalSalesAmount(formattedTotalSales);
        setTotalCommission(formattedTotalComm);
  
        // Append the total row to the data source
        const totalRow = {
          Category: "Total",
          SalesAmount: formattedTotalSales,
          Commision: formattedTotalComm
        };
        formattedData.push(totalRow);
  
        // Update the state with the modified data source
        setDataSource(formattedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching recent orders data:", error);
        setLoading(false);
      }
    };
  
    fetchOrders();
  
  }, [AgentCode, Ledcode]);
  

  const columns = [
    { 
      title: "Category",
      dataIndex: "Category",
      align: "left", // Align Category column content to the left
    },
    {
      title: "Sales Amount",
      dataIndex: "SalesAmount",
      align: "right", // Align Sales Amount content to the right
    
      render: (text, record) => (
        <span style={{ color: record.Category === "Total" ? "red" : "inherit" }}>
          {text}
        </span>
      )
    }, 
    {
      title: "Target",
      dataIndex: "Target",
      align: "right", // Align Target content to the right
    },
    {
      title: "Commission",
      dataIndex: "Commision",
      align: "right", // Align Commission content to the right
      render: (text, record) => (
        <span style={{ color: record.Category === "Total" ? "red" : "inherit" }}>
          {text}
        </span>
      )
    }
  ];
  

  const rowClassName = (record) => {
    return record.Category === "Total" ? "total-row" : "";
  };

  return (
    <Card className="jsGoal jsGoalBorder">
      <Typography.Text className="jsGoal">JS Goal</Typography.Text>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={loading}
        rowClassName={rowClassName}
      />
    </Card>
  );
}


function TkProfit() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalSalesAmount, setTotalSalesAmount] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);

  const Ledcode = sessionStorage.getItem('Ledcode');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const AgentCode = sessionStorage.getItem('AgentCode');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${apiBaseUrl}/dashboard/categorywisetk/JAPAN_ACCESORIES?ledcode=${encodeURIComponent(Ledcode)}&agentCode=${encodeURIComponent(AgentCode)}`);
        const data = await response.json();
  
        // Calculate total SalesAmount and Commission
        let totalSales = 0;
        let totalComm = 0;
  
        const formattedData = data.map(item => {
          // Convert SalesAmount, Target, and Commision to 2 decimal places
          const formattedSalesAmount = item.SalesAmount.toFixed(2);
          const formattedTarget = item.Target.toFixed(2);
          const formattedCommision = item.Commision.toFixed(2);
  
          totalSales += item.SalesAmount;
          totalComm += item.Commision;
  
          return {
            Category: item.Category,
            SalesAmount: formattedSalesAmount,
            Target: formattedTarget,
            Commision: formattedCommision
          };
        });
  
        // Set total values
        const formattedTotalSales = totalSales.toFixed(2);
        const formattedTotalComm = totalComm.toFixed(2);
        
        setTotalSalesAmount(formattedTotalSales);
        setTotalCommission(formattedTotalComm);
  
        // Append the total row to the data source
        const totalRow = {
          Category: "Total",
          SalesAmount: formattedTotalSales,
          Commision: formattedTotalComm
        };
        formattedData.push(totalRow);
  
        // Update the state with the modified data source
        setDataSource(formattedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching recent orders data:", error);
        setLoading(false);
      }
    };
  
    fetchOrders();
  
  }, [AgentCode, Ledcode]);
  

  const columns = [
    { 
      title: "Category",
      dataIndex: "Category",
      align: "left", // Align Category column content to the left
    },
    {
      title: "Sales Amount",
      dataIndex: "SalesAmount",
      align: "right", // Align Sales Amount content to the right
    
      render: (text, record) => (
        <span style={{ color: record.Category === "Total" ? "red" : "inherit" }}>
          {text}
        </span>
      )
    }, 
    {
      title: "Target",
      dataIndex: "Target",
      align: "right", // Align Target content to the right
    },
    {
      title: "Commission",
      dataIndex: "Commision",
      align: "right", // Align Commission content to the right
      render: (text, record) => (
        <span style={{ color: record.Category === "Total" ? "red" : "inherit" }}>
          {text}
        </span>
      )
    }
  ];
  

  const rowClassName = (record) => {
    return record.Category === "Total" ? "total-row" : "";
  };

  return (
    <Card className="jsGoal jsGoalBorder">
      <Typography.Text className="jsGoal">TK Goal</Typography.Text>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={loading}
        rowClassName={rowClassName}
      />
    </Card>
  );
}

function Summary() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedRowData, setExpandedRowData] = useState({});
  const Ledcode = sessionStorage.getItem('Ledcode');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const AgentCode = sessionStorage.getItem('AgentCode');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${apiBaseUrl}/dashboard/summary/JAPAN_ACCESORIES?ledcode=${encodeURIComponent(Ledcode)}`);
        const data = await response.json();
  
        const formattedData = data.map(item => {
          // Convert SaleAmount to 2 decimal places, handling null values
          const rawSalesAmount = item.SaleAmount;
          const formattedSalesAmount = (rawSalesAmount !== null && !isNaN(rawSalesAmount))
            ? parseFloat(rawSalesAmount).toFixed(2)  // Convert to float and format to 2 decimal places
            : '0.00';  // If null or NaN, default to '0.00'
  
          const name = item.Name;

          const target = item.Target;
          const goal = item.Goal;
          const ledcode = item.Ledcode;

          
          return {
            key: name,

            Category: name,
            SalesAmount: formattedSalesAmount,
            Target: target,
            Goal: goal,
            Ledcode: ledcode

          };
        });
  
        // Update the state with the modified data source
        setDataSource(formattedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching summary data:", error);
        setLoading(false);
      }
    };
  
    fetchOrders();
  
  }, [AgentCode, Ledcode]);

  const fetchAdditionalData = async (key, ledcode) => {
    try {
      const response = await fetch(`${apiBaseUrl}/dashboard/categorywisecommission/JAPAN_ACCESORIES?ledcode=${encodeURIComponent(ledcode)}&key=${encodeURIComponent(key)}`);
      const data = await response.json();
      setExpandedRowData(prev => ({ ...prev, [key]: data }));
    } catch (error) {
      console.error("Error fetching additional data:", error);
    }
  };

  const handleExpand = (expanded, record) => {
    const keys = expanded ? [record.key] : [];
    setExpandedRowKeys(keys);

    if (expanded && !expandedRowData[record.key]) {
      fetchAdditionalData(record.key, record.Ledcode);
    }
  };
  

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "Category",
      align: "left",
      render: (text, record) => (
        <span style={{ color: record.Category === "Total" ? "red" : "inherit" }}>
          {text}
        </span>
      )
    },
   
    {
      title: "Sales Amount",
      dataIndex: "SalesAmount",
      align: "right", // Align Sales Amount content to the right
    
      render: (text, record) => (
        <span style={{ color: record.Category === "Total" ? "red" : "inherit" }}>
          {text}
        </span>
      )
    }, 
    {
      title: "Target",
      dataIndex: "Target",
      align: "right", // Align Sales Amount content to the right
    
      render: (text, record) => (
        <span style={{ color: record.Category === "Total" ? "red" : "inherit" }}>
          {text}
        </span>
      )
    }, 
    {
      title: "Goal %",
      dataIndex: "Goal",
      align: "right", // Align Sales Amount content to the right
    
      render: (text, record) => (
        <span style={{ color: record.Category === "Total" ? "red" : "inherit" }}>
          {text}
        </span>
      )
    }, 
    
   
  ];
  

  const rowClassName = (record) => {
    return record.Category === "Total" ? "total-row" : "";
  };

  const expandedRowRender = (record) => {
    const additionalData = expandedRowData[record.key];
    if (!additionalData) {
      return <Spin />;
    }

    const nestedColumns = [
      {
        title: "Category",
        dataIndex: "Category",
        key: "Category",
        align: "left", // Align Sales Amount content to the right

        render: (text) => <span style={{ color: 'red' }}>{text}</span>

      },
      {
        title: "Sales Amount",
        dataIndex: "SalesAmount",
        key: "SalesAmount",
        align: "right", // Align Sales Amount content to the right

        render: (text) => <span style={{ color: 'red' }}>{parseFloat(text).toFixed(2)}</span>
      },
      {
        title: "Target",
        dataIndex: "Target",
        key: "Target",
        align: "right", // Align Sales Amount content to the right

        render: (text) => <span style={{ color: 'red' }}>{parseFloat(text).toFixed(2)}</span>
      },
      {
        title: "Commission",
        dataIndex: "Commision",
        key: "Commision",
        align: "right", // Align Sales Amount content to the right

        render: (text) => <span style={{ color: 'red' }}>{parseFloat(text).toFixed(2)}</span>
      }
    ];
    return (
      <Table
        columns={nestedColumns}
        dataSource={additionalData}
        pagination={false}
        rowKey="Category"
      />
    );
  };


  return (
    <Card className="jsGoal jsGoalBorder">
      <Typography.Text className="jsGoal">Summary</Typography.Text>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={loading}
        rowClassName={rowClassName}
        expandable={{
          expandedRowKeys,
          onExpand: handleExpand,
          expandedRowRender
        }}
      />
    </Card>
  );
}

function Summary2() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandedRowData, setExpandedRowData] = useState({});
  const Ledcode = sessionStorage.getItem('Ledcode');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const AgentCode = sessionStorage.getItem('AgentCode');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${apiBaseUrl}/dashboard/summarytk/JAPAN_ACCESORIES?ledcode=${encodeURIComponent(Ledcode)}`);
        const data = await response.json();

        const formattedData = data.map(item => {
          const rawSalesAmount = item.SaleAmount;
          const formattedSalesAmount = (rawSalesAmount !== null && !isNaN(rawSalesAmount))
            ? parseFloat(rawSalesAmount).toFixed(2)
            : '0.00';

          const name = item.Name;
          const target = item.Target;
          const goal = item.Goal;
          const ledcode = item.Ledcode;

          return {
            key: name,
            Category: name,
            SalesAmount: formattedSalesAmount,
            Target: target,
            Goal: goal,
            Ledcode: ledcode
          };
        });

        setDataSource(formattedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching summary data:", error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, [AgentCode, Ledcode]);

  const fetchAdditionalData = async (key, ledcode) => {
    try {
      const response = await fetch(`${apiBaseUrl}/dashboard/categorywisetk/JAPAN_ACCESORIES?ledcode=${encodeURIComponent(ledcode)}&key=${encodeURIComponent(key)}`);
      const data = await response.json();
      setExpandedRowData(prev => ({ ...prev, [key]: data }));
    } catch (error) {
      console.error("Error fetching additional data:", error);
    }
  };

  const handleExpand = (expanded, record) => {
    const keys = expanded ? [record.key] : [];
    setExpandedRowKeys(keys);

    if (expanded && !expandedRowData[record.key]) {
      fetchAdditionalData(record.key, record.Ledcode);
    }
  };

  const columns = [
    
    {
      title: "Employee Name",
      dataIndex: "Category",
      align: "left",
      render: (text, record) => (
        <span style={{ color: record.Category === "Total" ? "red" : "inherit" }}>
          {text}
        </span>
      )
    },
    {
      title: "Sales Amount",
      dataIndex: "SalesAmount",
      align: "right",
      render: (text, record) => (
        <span style={{ color: record.Category === "Total" ? "red" : "inherit" }}>
          {text}
        </span>
      )
    },
    {
      title: "Target",
      dataIndex: "Target",
      align: "right",
      render: (text, record) => (
        <span style={{ color: record.Category === "Total" ? "red" : "inherit" }}>
          {text}
        </span>
      )
    },
    {
      title: "Goal %",
      dataIndex: "Goal",
      align: "right",
      render: (text, record) => (
        <span style={{ color: record.Category === "Total" ? "red" : "inherit" }}>
          {text}
        </span>
      )
    }
  ];

  const rowClassName = (record) => {
    return record.Category === "Total" ? "total-row" : "";
  };

  const expandedRowRender = (record) => {
    const additionalData = expandedRowData[record.key];
    if (!additionalData) {
      return <Spin />;
    }

    const nestedColumns = [
      {
        title: "Category",
        dataIndex: "Category",
        key: "Category",
        align: "left", // Align Sales Amount content to the right

        render: (text) => <span style={{ color: 'red' }}>{text}</span>

        
      },
      {
        title: "Sales Amount",
        dataIndex: "SalesAmount",
        key: "SalesAmount",
        align: "right", // Align Sales Amount content to the right

        render: (text) => <span style={{ color: 'red' }}>{parseFloat(text).toFixed(2)}</span>
      },
      {
        title: "Target",
        dataIndex: "Target",
        key: "Target",
        align: "right", // Align Sales Amount content to the right

        render: (text) => <span style={{ color: 'red' }}>{parseFloat(text).toFixed(2)}</span>
      },
      {
        title: "Commission",
        dataIndex: "Commision",
        key: "Commision",
        align: "right", // Align Sales Amount content to the right

        render: (text) => <span style={{ color: 'red' }}>{parseFloat(text).toFixed(2)}</span>
      }
    ];

    return (
      <Table
        columns={nestedColumns}
        dataSource={additionalData}
        pagination={false}
        rowKey="Category"
      />
    );
  };

  return (
    <Card className="jsGoal jsGoalBorder">
      <Typography.Text className="jsGoal">Summary</Typography.Text>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={loading}
        rowClassName={rowClassName}
        expandable={{
          expandedRowKeys,
          onExpand: handleExpand,
          expandedRowRender
        }}
      />
    </Card>
  );
}


function DashboardList({ title, data, columns }) {
  return (
    <Card className="list1">
      <Typography.Text>{title}</Typography.Text>
      <Table columns={columns} dataSource={data} pagination={false} />
    </Card>
  );
}

function DashboardChart({ revenueData }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Order Revenue",
      },
    },
  };

  return (

    <div className="card" >
      <Card style={{ width: 500, height: 295 }}>
        <Bar options={options} data={revenueData} />
      </Card>
    </div>

  );
}

export default React.memo(Main);
