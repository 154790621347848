import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Login'
import Home from './components/Home';
import Reviewlist from './components/Reviewlist';
import ImageModal from './components/Imagemodal';
import Cart from './components/Cart';
import Notification from './components/Notifictaion';
import Success from './components/Success';
import Main from './components/Main';
import Order from './components/Order';
import Signup from './components/Signup';
import Search from './components/Search';
import Otp from './components/Otp';
import About from './components/About';
import Signupmob from './components/Signupmob';
import Mainmob from './components/Mainmob';
import Admin from './components/Admin';
import Image from './components/Image';
import Contact from './components/Contact';
import Privacy from './components/Privacy';
import PosterUploader from './components/PosterUploader';

function App() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);   
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        
        {isMobileView ? (
        <Route path='/Signup' element={<Signupmob />} />
        ) : (
          <Route path='/Signup' element={<Signup />} />
           )}
        <Route path='/About' element={<About />} />
        <Route path='/Search' element={<Search />} />
        <Route path='/Home' element={<Home />} />
        <Route path='/Otp' element={<Otp />} />``
        <Route path='/Order' element={<Order />} />
        <Route path='/Success' element={<Success />} />
        <Route path='/Reviewlist' element={<Reviewlist />} />
        <Route path='/Cart' element={<Cart />} />
        <Route path='/Notification' element={<Notification />} />
        <Route path='/Imagemodal' element={<ImageModal />} />
        <Route path='/Admin' element={<Admin />} />
        <Route path='/Image' element={<Image/>} />
        <Route path='/Contact' element={<Contact/>} />
        <Route path='/privacy' element={<Privacy/>} />
        <Route path='/poster' element={<PosterUploader/>} />



        {isMobileView ? (
          <Route path='/Main' element={<Mainmob />} />
        ) : (      
          <Route path='/Main' element={<Main />} />
        )}
      
      </Routes>
    
   </BrowserRouter>
  );
}

export default App;
