import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PosterUploader = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedLogo, setSelectedLogo] = useState(null);
    const [message, setMessage] = useState('');
    const [posterUrl, setPosterUrl] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const apiLocalUrl = process.env.REACT_APP_API_LOCAL_URL;

    const handleFileChange = (event, type) => {
        if (type === 'poster') {
            setSelectedFile(event.target.files[0]);
        } else if (type === 'logo') {
            setSelectedLogo(event.target.files[0]);
        }
    };

    const handleUpload = async (type) => {
        const file = type === 'poster' ? selectedFile : selectedLogo;
        if (!file) {
            setMessage(`Please select a ${type} to upload.`);
            return;
        }

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axios.post(`${apiLocalUrl}/upload-${type}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessage(response.data);
            if (type === 'poster') fetchPoster();
            if (type === 'logo') fetchLogo();
        } catch (error) {
            setMessage(`Failed to upload ${type}. ` + error.response?.data || error.message);
        }
    };

    const fetchPoster = async () => {
        try {
            const url = `${apiLocalUrl}/poster/poster.jpg`;
            setPosterUrl(url);
        } catch (error) {
            setMessage('Failed to fetch poster.');
        }
    };

    const fetchLogo = async () => {
        try {
            const url = `${apiLocalUrl}/logo/logo.jpg`;
            setLogoUrl(url);
        } catch (error) {
            setMessage('Failed to fetch logo.');
        }
    };

    const handleDelete = async (type) => {
        try {
            const response = await axios.delete(`${apiLocalUrl}/delete-${type}`);
            setMessage(response.data);
            if (type === 'poster') setPosterUrl('');
            if (type === 'logo') setLogoUrl('');
        } catch (error) {
            setMessage(`Failed to delete ${type}. ` + error.response?.data || error.message);
        }
    };

    useEffect(() => {
        fetchPoster();
        fetchLogo();
    }, []);

    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Upload Poster and Logo</h2>

            {/* Poster Upload */}
            <div style={styles.sectionContainer}>
                <h3 style={styles.subHeader}>Poster</h3>
                <input 
                    type="file" 
                    accept="image/*" 
                    onChange={(e) => handleFileChange(e, 'poster')} 
                    style={styles.input} 
                />
                <button 
                    onClick={() => handleUpload('poster')} 
                    style={styles.button}
                >
                    Upload Poster
                </button>
                {posterUrl && (
                    <div style={styles.posterContainer}>
                        <h4 style={styles.posterHeader}>Current Poster:</h4>
                        <img 
                            src={posterUrl} 
                            alt="Poster" 
                            style={styles.posterImage} 
                        />
                        <button 
                            onClick={() => handleDelete('poster')} 
                            style={styles.button}
                        >
                            Delete Poster
                        </button>
                    </div>
                )}
            </div>

            {/* Logo Upload */}
            <div style={styles.sectionContainer}>
                <h3 style={styles.subHeader}>Logo</h3>
                <input 
                    type="file" 
                    accept="image/*" 
                    onChange={(e) => handleFileChange(e, 'logo')} 
                    style={styles.input} 
                />
                <button 
                    onClick={() => handleUpload('logo')} 
                    style={styles.button}
                >
                    Upload Logo
                </button>
                {logoUrl && (
                    <div style={styles.posterContainer}>
                        <h4 style={styles.posterHeader}>Current Logo:</h4>
                        <img 
                            src={logoUrl} 
                            alt="Logo" 
                            style={styles.posterImage} 
                        />
                        <button 
                            onClick={() => handleDelete('logo')} 
                            style={styles.button}
                        >
                            Delete Logo
                        </button>
                    </div>
                )}
            </div>

            <p style={styles.message}>{message}</p>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        maxWidth: '600px',
        margin: '0 auto',
        backgroundColor: '#f7f7f7',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    header: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '20px',
    },
    sectionContainer: {
        marginBottom: '30px',
        width: '100%',
    },
    subHeader: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#555',
        marginBottom: '10px',
    },
    input: {
        padding: '10px',
        fontSize: '16px',
        marginBottom: '10px',
        borderRadius: '4px',
        border: '1px solid #ddd',
        width: '100%',
        maxWidth: '400px',
    },
    button: {
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#4CAF50',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        marginBottom: '10px',
    },
    message: {
        fontSize: '16px',
        color: '#d9534f',
        textAlign: 'center',
    },
    posterContainer: {
        textAlign: 'center',
        marginTop: '20px',
    },
    posterHeader: {
        fontSize: '18px',
        color: '#333',
        marginBottom: '10px',
    },
    posterImage: {
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '8px',
    },
};

export default PosterUploader;
